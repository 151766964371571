body{
  background-color: hsl(0, 100%, 99%);
  /* background-color: black;
  color: white; */
}

.font{
  font-family: Brush Script MT;
}

.font-1 {
font-family: 'Poppins', sans-serif;
font-weight: bold;
}

#font-1-color{
  color: #732FF9;
}

.fontColor{
  color: #732FF9;
}

.normalFont {
  font-family: 'Poppins', sans-serif;
}

#bgColor{
  background-color: #732FF9;
}

#home{
  margin-top: 200px;
}

#About{
  margin-top: 300px;
}

.Contact{
  margin-top: 200px;
}

.Projects{
  margin-top: 280px;
}

#Block{
  color: white;
}